






































































import { Component, Watch } from 'vue-property-decorator'

import { SelectItem } from '../../../dsl/atoms/Select'
import { SelenaForm } from './SelenaFormWrapper'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component({
  name: 'Webinar',
})
export class Webinar extends SelenaForm {
  public formData = {
    name: '',
    email: '',
    phoneNumber: '',
    webinar: ''
  }

  public get webinarOptions () {
    return this.options.webinar || [] as SelectItem[]
  }

  public get route () {
    return this.$route.fullPath
  }

  @Watch('route')
  onRouteChange () {
    const webinarId = this.$route.query?.webinar

    if (!webinarId) return
    if (!this.webinarOptions.length) return

    const foundOption = [...this.webinarOptions].find((option: AnyObject) => {
      return option.value === webinarId
    }) as SelectItem

    if (!foundOption) return

    this.formData.webinar = foundOption.value as string
  }
}
export default Webinar
